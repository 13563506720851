<template>
    <v-container fluid fill-height>
        <v-row>
            <v-col></v-col>
            <v-col>
                <v-card class="elevation-6" style="min-width:320px">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>注册</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field prepend-icon="person" name="login" label="邮箱" type="email" :rules="[rules.required, rules.email]" v-model="email">
                            </v-text-field>
                            <v-text-field id="password" prepend-icon="lock" name="password" label="密码" type="password" :rules="[rules.required, rules.counter]" v-model="password"></v-text-field>
                            <v-text-field id="password2" prepend-icon="lock" name="password2" label="重复密码" :rules="[rules.required, rules.counter]" v-model="password2" type="password" maxlength=10></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" to='/auth/local'>去登录</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="Register">确认</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col></v-col>
        </v-row>
    </v-container>
</template>
<script>
import api from '@/api'

export default {
    components: {},
    data() {
        return {
            email: '',
            password: '',
            password2: '',
            rules: {
                required: value => !!value || '必须',
                counter: value => value.length <= 14 && value.length >= 6 || '6 ~ 12个字符',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || '请检查邮件地址'
                }
            }
        }
    },
    props: [],
    methods: {
        Register() {
            if (!this.password || !this.password2 || !this.email) {
                this.$alert.warning({ message: '请检查填写的项目' });
                return
            }
            if (this.password.length < 6 || this.password.length > 14) {
                this.$alert.warning({ message: '密码要求6 ~ 12个字符' });
                return
            }
            if (this.email) {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!pattern.test(this.email)) {
                    this.$alert.warning({ message: '请检查邮件地址' });
                    return
                }
            }
            console.log(this.password, this.password2)

            if (this.password !== this.password2) {
                // this.$alert.warning({ message:  });
                this.$Event.emit('alert', {'type': 'error', 'msg': '两次密码必须相同'})
                return
            }

            api.auth_local_register({
                username: this.email,
                email: this.email,
                password: this.password
            }).then(res => {
                this.$Event.emit('auth_done', res)
                this.$Event.emit('alert', {'type': 'error', 'msg': '注册成功'})
            })
        },
    },
    mounted: function() {},
    beforeDestroy() {
        this.password = null
        this.username = null
        this.rules = null
    },
}
</script>
<style scoped>
</style>